var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultCrudView',{attrs:{"breadcrumbsItems":_vm.breadcrumbs,"actions":_vm.actions,"containerMedium":""},on:{"create-user":_vm.createUserModal,"user-multi-select":_vm.userMultiSelectModal}},[_c('template',{slot:"content"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.itemsLength,"footer-props":_vm.$defaultDataTableFooterProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.manager",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.manager),callback:function ($$v) {_vm.$set(item, "manager", $$v)},expression:"item.manager"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.openRevokeModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-remove ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('remove')))])])]}}])}),_c('DefaultModal',{ref:"revokeUserModal",attrs:{"title":_vm.$t('Revoke user from group'),"submit-button-text":"revoke","deleteModal":""},on:{"submit":_vm.revokeUser},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Are you sure you want to revoke user'))+" "),_c('b',[_vm._v(_vm._s(_vm.selectedUser ? _vm.selectedUser.name : ''))]),_vm._v(" "+_vm._s(_vm.$t(' from group?'))+" ")]},proxy:true}])}),_c('DefaultModal',{ref:"createUserModal",attrs:{"title":_vm.$t('create_users')},on:{"submit":function($event){return _vm.saveUsers(_vm.usersToCreate)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('UserEmailTextarea',{attrs:{"rules":[function (v) { return !!v || _vm.$t('form_rules.mandatory'); }]},model:{value:(_vm.usersToCreate),callback:function ($$v) {_vm.usersToCreate=$$v},expression:"usersToCreate"}}),_c('v-switch',{attrs:{"label":_vm.$t('manager')},model:{value:(_vm.isManager),callback:function ($$v) {_vm.isManager=$$v},expression:"isManager"}})]},proxy:true}])}),_c('DefaultModal',{ref:"userMultiSelectModal",attrs:{"title":_vm.$t('select_users')},on:{"submit":function($event){return _vm.saveUsers(_vm.multiSelectedUsers)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('UserMultiSelect',{attrs:{"items":_vm.selectUsersList,"rules":[function (v) { return (v && v.length > 0) || _vm.$t('form_rules.mandatory'); }]},model:{value:(_vm.multiSelectedUsers),callback:function ($$v) {_vm.multiSelectedUsers=$$v},expression:"multiSelectedUsers"}}),_c('v-switch',{attrs:{"label":_vm.$t('manager')},model:{value:(_vm.isManager),callback:function ($$v) {_vm.isManager=$$v},expression:"isManager"}})]},proxy:true}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }