<template>

  <DefaultCrudView
    @create-user="createUserModal"
    @user-multi-select="userMultiSelectModal"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
    containerMedium
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="users"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :footer-props="$defaultDataTableFooterProps"
      >
        <template v-slot:item.manager="{ item }">
          <v-checkbox v-model="item.manager" disabled></v-checkbox>
        </template>

        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="openRevokeModal(item)"
              >
                mdi-account-remove
              </v-icon>
            </template>
            <span>{{ $t('remove') }}</span>
          </v-tooltip>

        </template>

      </v-data-table>

      <DefaultModal
        :title="$t('Revoke user from group')"
        ref="revokeUserModal"
        @submit="revokeUser"
        submit-button-text="revoke"
        deleteModal
      >
        <template v-slot:content>
          {{ $t('Are you sure you want to revoke user') }} <b>{{ selectedUser ? selectedUser.name : '' }}</b> {{ $t(' from group?') }}
        </template>
      </DefaultModal>

      <DefaultModal :title="$t('create_users')" ref="createUserModal" @submit="saveUsers(usersToCreate)">
        <template v-slot:content>
          <UserEmailTextarea 
            v-model="usersToCreate" 
            :rules="[v => !!v || $t('form_rules.mandatory')]"
          />

          <v-switch
            :label="$t('manager')"
            v-model="isManager"
          >
          </v-switch>
        </template>
      </DefaultModal>

      <DefaultModal :title="$t('select_users')" ref="userMultiSelectModal" @submit="saveUsers(multiSelectedUsers)">
        <template v-slot:content>
          <UserMultiSelect 
            v-model="multiSelectedUsers"
            :items="selectUsersList"
            :rules="[v => (v && v.length > 0) || $t('form_rules.mandatory')]"
          />

          <v-switch
            :label="$t('manager')"
            v-model="isManager"
          >
          </v-switch>

        </template>
      </DefaultModal>

    </template>

  </DefaultCrudView>

</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }
</style>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import UserMultiSelect from '@/components/core/UserMultiSelect.vue'
import UserEmailTextarea from '@/components/core/UserEmailTextarea.vue'

import AppActions from '@/store/app/actions-types'
import GroupActions from '@/store/core/group/actions-types'
import GroupMutations from '@/store/core/group/mutations-types'
import UserActions from '@/store/core/user/actions-types'

export default {
  name: "GroupUsers",

  components: {
    DefaultCrudView,
    DefaultModal,
    UserMultiSelect,
    UserEmailTextarea
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('groups'),
          prependIcon: this.getIconForCoreSettingsItem('groups'),
          iconColor: 'primary',
          to: {
            name: "Group"
          },
          disabled: false,
          exact: true,
          ripple: true,
        },
        {
          text: !this.selectedGroup ? '' : `${this.selectedGroup.group_title} - ${this.$t('users')}`,
          disabled: true,
        }
      ]
    },

    ...mapState({
      selectedGroup: state => state.core.group.selectedGroup
    }),

    ...mapGetters('app', [
      'getIconForCoreSettingsItem'
    ])
  },

  data() {
    return {
      loading: false,
      users: [],
      options: {},
      itemsLength: 0,
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Email', value: 'email'},
        {text: 'Manager', value: 'manager'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      selectedUser: null,
      selectUsersList: [],
      multiSelectedUsers: [],
      usersToCreate: [],
      actions: [
        {
          text: "Create Users",
          eventToEmit: "create-user"
        },
        {
          text: "Select Users",
          eventToEmit: "user-multi-select" 
        },
      ],
      isManager: false
    }
  },

  mounted() {
    this.loading = true

    if (!this.selectedGroup) {
      this.[GroupActions.GET_USER_GROUP](this.$route.params.groupId)
        .then((userGroup) => {
          this.[GroupMutations.SET_SELECTED_GROUP](userGroup)
          this.reloadData()
        })
    } else {
      this.reloadData()
    }
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true
    },

    selectedGroup: {
      handler () {
        this.reloadData()
      },
      deep: true
    }
  },

  methods: {
    ...mapActions('core/group', [
      GroupActions.GET_USER_GROUP,
      GroupActions.GET_USERS_FROM_GROUP,
      GroupActions.DELETE_USER_FROM_GROUP
    ]),

    ...mapMutations('core/group', [
      GroupMutations.SET_SELECTED_GROUP
    ]),

    ...mapActions('core/user', [
      UserActions.GET_ALL_USERS,
      UserActions.CREATE_USER_BATCH
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
    ]),

    reloadData() {
      if (!this.selectedGroup) {
        return
      }

      let options = {}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.page - 1


      this.loading = true
      this.[GroupActions.GET_USERS_FROM_GROUP]({userGroupId: this.selectedGroup.user_group_id, options: options})
        .then((result) => {
          this.users = [...result.items]

          this.itemsLength = result.total

          this.getUsersToSelect()
        })
        .catch(() => {
          this.loading = false
        })
    },

    getUsersToSelect() {
      const options = { 'filter[not_admin_tenant]': this.tenantId, 'limit': 9999 }

      this.[UserActions.GET_ALL_USERS](options)
        .then((result) => {
          this.selectUsersList = [...result.items]

          this.loading = false
        })
    },

    openRevokeModal(item) {
      this.selectedUser = item
      this.$refs.revokeUserModal.openModal()
    },

    revokeUser() {
      this.loading = true
      let params = {userGroupId: this.selectedGroup.user_group_id, userId: this.selectedUser.users_id}
      this.[GroupActions.DELETE_USER_FROM_GROUP](params)
        .then(() => {
          this.reloadData()
          this.$refs.revokeUserModal.closeModal()
        })
        .catch(() => {
          this.loading = false
          this.$refs.revokeUserModal.closeModal()
        })
    },

    createUserModal() {
      this.$refs.createUserModal.openModal()
    },

    userMultiSelectModal() {
      this.$refs.userMultiSelectModal.openModal()
    },

    saveUsers(userEmails) {
      const companyId = this.$route.params.companyId
      let tenantId = this.$route.params.originTenant

      if (!tenantId) {
        tenantId = this.selectedGroup.tenant_id
      }

      const params = {
        emails: userEmails,
        userGroupId: this.selectedGroup.user_group_id,
        tenantId: tenantId,
        companyId: companyId,
        type: this.isManager ? 'manager' : 'user',
        link: window.location.origin
      }

      this.[UserActions.CREATE_USER_BATCH](params)
        .then(() => {
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('User(s) added'))

          this.$refs.createUserModal.closeModal()
          this.$refs.userMultiSelectModal.closeModal()

          this.reloadData()
        })
        .catch(() => this.$refs.createUserModal.submitting = false)
    }
  }
}

</script>